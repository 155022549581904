/**
 * It formats a number to a string with a given number of fraction digits and locales
 *
 * @param {number | string} value - The value to be formatted.
 * @param {{ locales: string?, fractDigits: number?, percent: boolean? }?} options - Formatting options.
 * @returns {string} A formatted number.
 */

interface IOptions {
  locales?: string;
  fractDigits?: number;
  percent?: boolean;
}

export const formatNumber = (value: number, options: IOptions = {}) => {
  if (isNaN(Number(value))) {
    return value;
  }

  const { fractDigits = 0, locales = "en-US", percent = false } = options;

  try {
    return new Intl.NumberFormat(
      locales,
      {
        style: percent ? "percent" : "decimal",
        maximumFractionDigits: fractDigits,
        minimumFractionDigits: fractDigits,
      },
    ).format(value || 0);
  } catch (e) {
    return value.toString();
  }
};
