import React, { useState, useEffect, useRef, LegacyRef } from "react";
import dynamic from "next/dynamic";

// interfaces
import { IHomeMain } from "./IHomeMain";

// components
import HomeMainCarousel from "./components/HomeMainCarousel";
import ButtonHomeMain from "./components/HomeMainButton";
import ArrowRightLong from "@/components/icons/ArrowRightLong";
const LocationsEvents = dynamic(() => import("./components/LocationsEvents"), { ssr: false });
const HomePageBrands = dynamic(() => import("@/components/HomeBrands"), { ssr: false });

// helpers
import { useDimension } from "@/hooks/useDimensions";
import useUserProfile from "@/hooks/useUserProfile";
import { formatNumber } from "@/landlord/helpers/numberUtils";

// assets
import styles from "@/assets/styles/containers/home-main.module.scss";

const HomeMain = (props: IHomeMain) => {
  const {
    spacesCount,
    spaceImages,
    placeImages,
    typesOfLocations,
    typesOfEvents,
    brandLogos,
    containerRef,
    brandRef,
    isDesktop,
    scrollToContact
  } = props;

  const [textHeight, setTextHeight] = useState<number | undefined>(172);
  const locEventsMaxTextHeightRef = useRef<HTMLElement>(null);

  const [width] = useDimension();

  const { isAuthorized } = useUserProfile();

  // Effects
  useEffect(() => {
    if (width <= 1281) {
      setTimeout(() => {
        const { height } = locEventsMaxTextHeightRef.current?.getBoundingClientRect() || {};
        setTextHeight(height);
      }, 500);
    }
  }, [width]);

  return (
    <section className={styles["locl-home-main"]} ref={containerRef} data-testid="locl-home-main">
      {!!spaceImages.length && !!placeImages.length && (
        <h2 className={styles["locl-home-main__title"]} data-testid="locl-home-main-title">Location Live Services</h2>
      )}
      <div className={styles["locl-home-main__looking-for"]}>
        {!!spaceImages.length && (
          <div className={styles["locl-home-main__looking-for__single-item"]} data-testid="locl-home-main-space-info">
            <HomeMainCarousel
              images={spaceImages || []}
              isDesktop={isDesktop}
              wrapperName={styles["locl-home-main__carousel"]}
            />
            <h3 className={styles["locl-home-main__looking-for__single-item__title"]}>
              lo:live - The global <br /> marketplace for space
            </h3>
            <p
              style={{ height: isDesktop ? textHeight : "auto" }}
              className={styles["locl-home-main__looking-for__single-item__text"]}
            >
              Direct access to over {formatNumber(+spacesCount)} premium brand activation
              spaces globally, with live availability and direct pricing.
              lo:live is a self managed end to end planning and booking platform, and
              you always have the back up of our expert concierge team if you need support.
              <br/>
              Search outstanding spaces in 14 countries now.
            </p>
            <div className={styles["locl-home-main__looking-for__single-item__button-row"]}>
              <ButtonHomeMain
                to="/lolive"
                target="_blank"
                rel="noreferrer"
                onClick={(e: React.MouseEvent) => {
                  if (isAuthorized) {
                    e.preventDefault();
                    window.open("/go-search", "_blank");
                  }
                }}
                iconRight={
                  <ArrowRightLong
                    className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                  />
                }
              >
                Go to LO:LIVE
              </ButtonHomeMain>
              <ButtonHomeMain
                type="secondary"
                iconRight={
                  <ArrowRightLong
                    className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                    color={"rgb(254, 97, 97)"}
                  />
                }
                onClick={scrollToContact}
                data-testid="locl-home-main-space-info-button-brief"
              >
                Brief us
              </ButtonHomeMain>
            </div>

            <div className={styles["locl-home-main__location-events__single-item"]}>
              <h3 className={styles["locl-home-main__location-events__single-item__title"]}>
                Browse and book our popular activation&nbsp;spaces
              </h3>
              <LocationsEvents
                data={typesOfLocations}
                wrapperClass={styles["locl-home-main__location-events__left"]}
                isMobile={width < 870}
              />
            </div>
          </div>
        )}
        <div className={styles["locl-home-main__divider"]} />
        {!!placeImages.length && (
          <div className={styles["locl-home-main__looking-for__single-item"]} data-testid="locl-home-main-place-info">
            <HomeMainCarousel
              images={placeImages || []}
              isDesktop={isDesktop}
              wrapperName={styles["locl-home-main__carousel"]}
            />
            <h3 className={styles["locl-home-main__looking-for__single-item__title"]}>
              Studio - Our global <br/> scouting team
            </h3>
            <p
              ref={locEventsMaxTextHeightRef as LegacyRef<HTMLParagraphElement> | undefined}
              className={styles["locl-home-main__looking-for__single-item__text"]}
            >
              Need something truly unique? Unicorn hunting is our speciality.
              From turning a chicken shop into an event space to floating giant structures down a
              river, we’ve got you covered. With over 2,000 exclusive hire venues - from retail units
              to warehouses - we’ll find your perfect spot. Let’s make it happen.
            </p>
            <ButtonHomeMain
              color="green"
              onClick={scrollToContact}
              iconRight={
                <ArrowRightLong
                  className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                />
              }
              data-testid="locl-home-main-place-info-button-brief"
            >
              Brief us
            </ButtonHomeMain>
            <div className={styles["locl-home-main__location-events__single-item"]}>
              <h3 className={styles["locl-home-main__location-events__single-item__title"]}>
                See our location expertise in action
              </h3>
              <LocationsEvents
                data={typesOfEvents}
                wrapperClass="location-events-right"
                isMobile={width < 870}
              />
            </div>
          </div>
        )}
      </div>
      <div ref={brandRef}>
        <HomePageBrands customBrands={brandLogos} filterColor="rgba(243, 244, 246, 1)" />
      </div>
    </section>
  );
};

export default HomeMain;
