import { useState, useEffect, RefObject } from "react";

const useOnScreen = (element: RefObject<HTMLDivElement>, rootMargin?: string) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    const observeElement = element.current;
    if (observeElement) {
      observer.observe(element.current);
    }

    return () => {
      if (observeElement) {
        observer.unobserve(observeElement as HTMLDivElement);
      }
    };
  }, [element.current]);

  return isVisible;
};

export default useOnScreen;
