import { useParams } from "next/navigation";
import get from "lodash/get";

import aus from "@/dictionaries/aus";
import uk from "@/dictionaries/uk";

function useInternationalization(initPath?: string) {
  const { lang } = useParams();

  return (path: string) => get(lang === "aus" ? aus : uk, [initPath, path].filter(Boolean).join("."), null);
}

export default useInternationalization;
