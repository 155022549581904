import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import classNames from "classnames";

// interfaces
import { IHomeIntro } from "./IHomeIntro";

// components
import VideoPlayer from "@/components/VideoPlayer";

// helpers
import { ANIMATION_WORDS } from "./helpers";
import useOnScreen from "@/hooks/useOnScreen";
import useInternationalization from "@/hooks/useInternationalization";

// assets
import videoPlaceholder from "@/assets/images/landing/videoPlaceholder.svg";
import arrowDown from "@/assets/images/landing/arrow_down.svg";

import styles from "@/assets/styles/containers/home-intro.module.scss";

function HomeIntro(props: IHomeIntro) {
  const { heroSection, isDesktop, onButtonScrollClick } = props;

  const dictionary = useInternationalization("home.banner");

  const [counter, setCounter] = useState(0);
  const [animateClass, setAnimateClass] = useState("");

  const animationRef = useRef(null);
  const animationsTagsRefs = useRef<HTMLSpanElement[]>([]);

  const animationVisible = useOnScreen(animationRef);

  const lastHeroSectionElement = heroSection.length ? heroSection[heroSection.length - 1] : null;

  // effects
  useEffect(() => {
    setAnimateClass(styles.bounce);

    setTimeout(() => setAnimateClass(""), 300);
  }, [counter]);

  //words animations
  useEffect(() => {
    if (!animationVisible) {
      return;
    }

    let closedCounter = 0;

    const id = setInterval(() => {
      closedCounter++;
      if (closedCounter === ANIMATION_WORDS.length) {
        closedCounter = 0;
      }
      setCounter(closedCounter);
    }, 1400);

    return () => {
      clearInterval(id);
    };
  }, [animationVisible]);

  // renders
  const renderAnimationWordsTag = () => {
    return ANIMATION_WORDS.map((el, index, self) => (
      <span
        key={index + el}
        ref={(ref: HTMLSpanElement) => {
          if (animationsTagsRefs.current) {
            if (!animationsTagsRefs.current.includes(ref)) {
              animationsTagsRefs.current.push(ref);
            }
          }
        }}
        data-index={index}
        className={classNames(
          styles.animated_word,
          { [styles.fadedIn]: index === counter },
          {
            [styles.fadedOut]: index === counter - 1 || (counter === 0 && index === self.length - 1)
          }
        )}
      >
        {el}
      </span>
    ));
  };

  return (
    <section className={styles["locl-home-intro"]} data-testid="locl-home-intro">
      {lastHeroSectionElement && lastHeroSectionElement.isVideo ? (
        <VideoPlayer
          src={(lastHeroSectionElement && lastHeroSectionElement.background) || videoPlaceholder}
          className={styles["locl-home-intro__image-bg"]}
        />
      ) : (
        <Image
          width={1920}
          height={715}
          src={(lastHeroSectionElement && lastHeroSectionElement.background) || videoPlaceholder}
          alt="video"
          className={styles["locl-home-intro__image-bg"]}
          data-testid="locl-home-intro-image-bg"
        />
      )}
      <div className={styles["locl-home-intro__welcome"]}>
        <h1 className={styles["locl-home-intro__welcome__title"]}>
          {dictionary("welcome")}
          <br />
        </h1>
        <div className={`${styles.animation_text} ${animateClass}`} ref={animationRef}>
          location<label>:</label>
          <div className={styles["animated_word-hidden"]}>{renderAnimationWordsTag()}</div>
        </div>
        <p className={styles["locl-home-intro__welcome__subtitle"]}>
          Locations for events and experiences
        </p>
        <p className={styles["locl-home-intro__welcome__content"]}>
          Location Live specialise in the planning and placement of locations for brand activations and
          events worldwide. We use a network of resources and proprietary technology to help brands and
          agencies find the optimum space to bring their experiences to life.
        </p>
      </div>
      <div className={styles["locl-home-intro__welcome__brands"]}>
        <div
          className={styles["locl-home-intro__welcome__brands__scroll"]}
          onClick={onButtonScrollClick}
          data-testid="locl-home-intro-scroll-down"
        >
          <div className={styles["locl-home-intro__welcome__brands__scroll__icon"]}>
            <Image
              width={20}
              height={12}
              className={styles["locl-home-intro__welcome__brands__scroll__icon__img"]}
              src={arrowDown}
              alt="arrow down"
            />
          </div>
          <p className={styles["locl-home-intro__welcome__brands__scroll__text"]} data-testid="locl-home-intro-scroll-text">
            {isDesktop ? "Scroll down" : "See more"}
          </p>
        </div>
      </div>
    </section>
  );
}

export default HomeIntro;
