import React from "react";

const ArrowRightLong = ({ color = "white", ...props }) => {
  return (
    <span {...props}>
      <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 4.5013H1.5H16.5Z" fill="white" />
        <path
          d="M13.1667 1.16797L16.5 4.5013M16.5 4.5013L13.1667 7.83464M16.5 4.5013H1.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default ArrowRightLong;
