"use client";

import { useRef, useEffect } from "react";
import dynamic from "next/dynamic";

// interfaces
import { IStatisticNumbers } from "@/types/api/StatisticNumbers";

// components
import HomeIntro from "@/containers/HomeIntro";
import HomeMain from "@/containers/HomeMain";
const HomeContactUs = dynamic(() => import("@/containers/HomeContactUs"), { ssr: false });

// helpers
import {
  BrandsItemProps,
  LandingItemProps,
  HeroSectionDataProps,
  PlaceSpaceImageItemProps
} from "@/types/api/Landing";
import { useDimension } from "@/hooks/useDimensions";
import { scrollToRef } from "@/hooks/scrollToRef";

interface HomePageComponentProps {
  typesOfLocations: LandingItemProps[];
  typesOfEvents: LandingItemProps[];
  brandLogos: BrandsItemProps[];
  heroSection: HeroSectionDataProps[];
  spacesImages: PlaceSpaceImageItemProps[];
  placeImages: PlaceSpaceImageItemProps[];
  statisticNumbers: IStatisticNumbers;
  initWidth: number;
}

export default function HomePageComponent(props: HomePageComponentProps) {
  const {
    typesOfLocations,
    typesOfEvents,
    heroSection,
    brandLogos,
    spacesImages,
    placeImages,
    statisticNumbers,
    initWidth
  } = props;

  const mainSectionRef = useRef(null);
  const mainSectionBrandRef = useRef(null);
  const contactUsRef = useRef(null);

  const [width] = useDimension({ initWidth });
  const isDesktop = width > 870;

  // Methods
  const homeIntroScroll = () => {
    scrollToRef(mainSectionRef);
  };

  const homeMainScroll = () => {
    scrollToRef(contactUsRef);
  };

  // Effects
  useEffect(() => {
    if (location.hash === "#brief-us") {
      setTimeout(() => {
        scrollToRef(contactUsRef);
      }, 600)
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <div className="home-page" data-testid="locl-home-page-component">
      <HomeIntro
        heroSection={heroSection}
        isDesktop={isDesktop}
        onButtonScrollClick={homeIntroScroll}
      />
      <HomeMain
        spaceImages={spacesImages}
        placeImages={placeImages}
        typesOfLocations={typesOfLocations}
        typesOfEvents={typesOfEvents}
        brandLogos={brandLogos}
        containerRef={mainSectionRef}
        brandRef={mainSectionBrandRef}
        isDesktop={isDesktop}
        scrollToContact={homeMainScroll}
        spacesCount={statisticNumbers.spaces}
      />
      <HomeContactUs containerRef={contactUsRef} isDesktop={isDesktop} />
    </div>
  );
}
