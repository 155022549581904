import { useRef, useEffect } from "react";
import Image from "next/image";
import SwiperCore from "swiper";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// interfaces
import { IHomeMainCarousel } from "../IHomeMain";

// styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import styles from "@/assets/styles/containers/home-main.module.scss";
import blur from "@/assets/images/blur.jpeg";


const HomeMainCarousel = ({ images, wrapperName = "", isDesktop }: IHomeMainCarousel) => {
  const ref = useRef<SwiperCore | null>(null);

  // temporary solution for swiper autoplay at safari mobile
  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.autoplay?.stop();
        ref.current?.autoplay?.start();
      }, 500);
    }
  }, [ref.current]);
  
  return (
    <Swiper
      onBeforeInit={(swiper) => {
        ref.current = swiper;
      }}
      className={wrapperName}
      pagination={{ clickable: false }}
      effect="fade"
      modules={[EffectFade, Pagination, Autoplay]}
      slidesPerView={1}
      autoplay={true}
      loop={true}
    >
      {images.map((item, index) => (
        <SwiperSlide key={item.image}>
          <Image
            width={isDesktop ? 516 : window.innerHeight}
            height={isDesktop ? 320 : 140}
            src={item.image || ""}
            alt={item.altTag}
            className={styles["locl-home-main__swiper-img"]}
            quality={isDesktop ? 75 : 25}
            priority={index === 0}
            blurDataURL={blur.blurDataURL}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeMainCarousel;
